<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="레코스 샘플배지"
            sub-title="배지 지갑 서비스 체험하기"
            bg-color=""
        />

        <!-- ANCHOR: 문의하기 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row
                    class="d-flex justify-center"
                    no-gutters
                >
                    <v-col class="pt-0 pb-0">
                        <p class="le-text-subtitle2 text-center">
                            레코스의 샘플배지를 발행 받아 배지 지갑을 체험하실 수 있습니다.<br>
                            배지 이미지를 클릭하시면 샘플 배지의 내용을 확인하실 수 있습니다.
                        </p>
                    </v-col>
                </v-row>

                <div
                    class="d-flex align-center justify-center"
                    :class="{
                        'py-25': $vuetify.breakpoint.smAndUp,
                        'py-15': $vuetify.breakpoint.xsOnly                    
                    }"
                >
                    <div>
                        <a
                            href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/QXNJNU5Eb0xLV0RNcjZ1SDhPa3RqZz09"
                            target="_blank"
                            class="d-flex"
                        >
                            <object
                                type="image/svg+xml"
                                class="no-pointer-events"
                                :data="require('@/assets/svg/others/issuing-sample-badge.svg')"
                                :width="$vuetify.breakpoint.mdAndUp ? 264 : ($vuetify.breakpoint.smOnly ? 264 : 239)"
                            >Your browser does not support SVG</object>
                        </a>
                    </div>
                </div>

                <p class="le-text-subtitle2 text-center">
                    아래 내용을 입력하시고 배지를 발행해 보세요.<br>
                    배지 발행 후 "오픈 배지 수여 알림"이 수신되기까지는 <strong>최대 한 시간</strong>이 걸립니다.
                </p>

                <!-- ANCHOR: Form-문의하기 -->
                <div class="mt-10">
                    <v-form ref="form">
                        <!-- 이름 -->
                        <v-row
                            no-gutters
                            class="d-flex flex-column justify-center align-center"
                        >
                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="text-right mb-3"
                            >
                                <p class="text-red1">
                                    *표시는 필수 입력 항목입니다.
                                </p>
                            </v-col>

                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="pb-0 pt-0 mb-2"
                            >
                                <p class="contact_title mb-3">
                                    <strong class="le-text-subtitle3">이름</strong>
                                    <span class="le-text-body2 text-red1 ml-1">*</span>
                                </p>

                                <v-text-field
                                    v-model="name"
                                    :rules="[v => !!v || '이름을 입력해주세요.']"
                                    outlined
                                    color="blue1"
                                    :dense="$vuetify.breakpoint.smAndDown"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- 이메일 -->
                        <v-row
                            no-gutters
                            class="d-flex flex-column justify-center align-center"
                        >
                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="pb-0 pt-0 mb-2"
                            >
                                <p class="contact_title mb-3">
                                    <strong class="le-text-subtitle3">이메일</strong>
                                    <span class="le-text-body2 text-red1 ml-1">*</span>
                                </p>

                                <v-text-field
                                    v-model="email"
                                    :rules="[
                                        v => !!v || '이메일을 입력해주세요.',
                                        v => /.+@.+\..+/.test(v) || '유효한 이메일 주소를 입력해주세요.'
                                    ]"
                                    outlined
                                    color="blue1"
                                    placeholder="info@lecos.co.kr"
                                    :dense="$vuetify.breakpoint.smAndDown"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-10">
                            <v-col class="d-flex align-center justify-center pt-0 pb-0">
                                <v-btn
                                    color="orange1"
                                    :large="$vuetify.breakpoint.smAndUp"
                                    depressed
                                    class="text-white1"
                                    @click="showIssuingCheckDialogVisible"
                                >
                                    발행하기
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>

                <!-- ANCHOR: 절차 -->
                <p
                    class="le-heading5 text-center"
                    :class="{
                        'py-25': $vuetify.breakpoint.smAndUp,
                        'py-15': $vuetify.breakpoint.xsOnly
                    }"
                >
                    오픈 배지 수여 알림 메일 수신 후
                </p>

                <v-row class="d-flex justify-center align-center proccess">
                    <v-col
                        cols="12"
                        md="10"
                        sm="10"
                    >
                        <v-row>
                            <v-col
                                cols="6"
                                md="3"
                                class="d-flex flex-column justify-center align-center"
                            >
                                <div>
                                    <v-img
                                        :src="require('@/assets/img/issuing-sample-process-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        :height="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        alt=""
                                    />
                                </div>

                                <p
                                    class="le-text-subtitle2 proccess__label"
                                    :class="{
                                        'mt-10': $vuetify.breakpoint.smAndUp,
                                        'mt-5': $vuetify.breakpoint.xsOnly
                                    }"
                                >
                                    1. 수령절차시작<br>
                                    버튼 클릭
                                </p>
                            </v-col>
                            <v-col
                                cols="6"
                                md="3"
                                class="d-flex flex-column justify-center align-center"
                            >
                                <div>
                                    <v-img
                                        :src="require('@/assets/img/issuing-sample-process-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        :height="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        alt=""
                                    />
                                </div>

                                <p
                                    class="le-text-subtitle2 proccess__label"
                                    :class="{
                                        'mt-10': $vuetify.breakpoint.smAndUp,
                                        'mt-5': $vuetify.breakpoint.xsOnly
                                    }"
                                >
                                    2. 지갑 계정 생성<br>
                                    절차 진행
                                </p>
                            </v-col>
                            <v-col
                                cols="6"
                                md="3"
                                class="d-flex flex-column justify-center align-center"
                            >
                                <div>
                                    <v-img
                                        :src="require('@/assets/img/issuing-sample-process-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        :height="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        alt=""
                                    />
                                </div>

                                <p
                                    class="le-text-subtitle2 proccess__label"
                                    :class="{
                                        'mt-10': $vuetify.breakpoint.smAndUp,
                                        'mt-5': $vuetify.breakpoint.xsOnly
                                    }"
                                >
                                    3. 최대 24시간 후<br>
                                    발행 완료 알림
                                </p>
                            </v-col>
                            <v-col
                                cols="6"
                                md="3"
                                class="d-flex flex-column justify-center align-center"
                            >
                                <div>
                                    <v-img
                                        :src="require('@/assets/img/issuing-sample-process-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        :height="$vuetify.breakpoint.mdAndUp ? 190 : ($vuetify.breakpoint.xsOnly ? 117 : 190)"
                                        alt=""
                                    />
                                </div>

                                <p
                                    class="le-text-subtitle2 proccess__label"
                                    :class="{
                                        'mt-10': $vuetify.breakpoint.smAndUp,
                                        'mt-5': $vuetify.breakpoint.xsOnly
                                    }"
                                >
                                    4. 배지 지갑에서<br>
                                    배지 확인
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row
                    no-gutters
                    class="text-center"
                    :class="{
                        'pt-25': $vuetify.breakpoint.smAndUp,
                        'pt-15': $vuetify.breakpoint.xsOnly
                    }"
                >
                    <v-col>
                        <p class="le-text-subtitle2">
                            배지 사용에 관한 자세한 내용은 배지 
                            <a
                                href="https://lecos.notion.site/8755f872723b4027a1e678a1de3961a5"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="text-blue3 l-text-button d-inline-flex"
                            ><span class="text">수령인 가이드</span></a>를 확인하세요.
                        </p>
                    </v-col>
                </v-row>

                <v-row class="mt-15">
                    <v-col class="d-flex align-center justify-center pt-0 pb-0">
                        <v-btn
                            :to="{ name: 'HomePageSelectedLanguage' }"
                            color="orange1"
                            :large="$vuetify.breakpoint.smAndUp"
                            depressed
                            class="text-white1"
                            exact
                        >
                            메인으로
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </section>

        <!-- ANCHOR: 다이얼로그-입력확인 -->
        <template>
            <v-dialog
                v-model="isIssuingCheckDialogVisible"
                width="500"
            >
                <v-card class="pa-0 rounded-lg">
                    <div class="le-heading6 pa-5">
                        입력 확인
                    </div>

                    
                    <div
                        v-if="isIssuingSuccessLoading"
                        class="center-absolute"
                    >
                        <v-progress-circular
                            indeterminate
                            color="orange1"
                            size="60"
                            width="10"
                        ></v-progress-circular>
                    </div>

                    <div
                        class="le-text-body2 pl-5 pr-6"
                        style="min-height: 88px;"
                    >
                        <p class="mb-3">
                            입력하신 내용은 다음과 같습니다.
                        </p>

                        <strong>{{ name }}</strong><br>
                        <strong>{{ email }}</strong><br>
                        
                        <p class="mt-3">
                            다음과 같이 발행 하시겠습니까?
                        </p>
                    </div>

                    <div class="pa-5 d-flex justify-end gap-3">
                        <v-btn
                            v-ripple="{ class: 'text-blue3' }"
                            color="blue1"
                            outlined
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleIssuingCheckDialogClose"
                        >
                            아니오
                        </v-btn>

                        <v-btn
                            color="orange1"
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            :class="{'disabled': isIssuingSuccessLoading }"
                            @click="handleSubmit"
                        >
                            네
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </template>

        <!-- ANCHOR: 다이얼로그-발행완료 -->
        <template>
            <v-dialog
                v-model="isIssuingSuccessDialogVisible"
                width="500"
            >
                <v-card class="pa-0 rounded-lg">
                    <div class="le-heading6 pa-5">
                        발행 완료
                    </div>

                    <div
                        class="le-text-body2 pl-5 pr-6"
                        style="min-height: 88px;"
                    >
                        오픈배지 발행이 완료되었습니다.<br>
                        입력하신 메일주소로 수여 알림이 발송됩니다.<br>
                        <span class="text-red1">자세한 사항은 페이지의 안내 내용을 참고해 주세요.</span>
                    </div>

                    <div class="pa-5 d-flex justify-end gap-3">
                        <v-btn
                            color="orange1"
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleIssuingSuccessDialogClose"
                        >
                            확인
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </template>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapActions } from 'vuex';
import { getSampleBadgesMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'SampleBadgesPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getSampleBadgesMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            isIssuingSuccessLoading: false,
            isIssuingCheckDialogVisible: false,
            isIssuingSuccessDialogVisible: false,
            lang: 'ko-KR',
            name: '',
            email: '',
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        ...mapActions(
            'issuing', [ 'issueBadge' ]
        ),

        /* ============================================================
         * ANCHOR: 발행하기 버튼 클릭
         * ============================================================ */
        showIssuingCheckDialogVisible() { 
            // 유효성 검사
            if (!this.$refs.form.validate()) {
                console.log('validate!!');
                return;
            }
            this.isIssuingCheckDialogVisible = true;
        },

        handleIssuingCheckDialogClose() {
            this.isIssuingCheckDialogVisible = false;
        },

        /* ============================================================
         * ANCHOR: 네(발행하기) 버튼 클릭
         * ============================================================ */
        async handleSubmit() {
            // this.handleIssuingCheckDialogClose();
            // this.isIssuingSuccessDialogVisible = true;

            this.isIssuingSuccessLoading = true;

            try {
                // 유효성 검사
                // if (!this.$refs.form.validate()) {
                //     console.log('validate!!');
                //     return;
                // }

                const { lang, name, email } = this;

                this.issueBadge({ name, email, lang })
                    .then(res => {
                        console.log('res', res);

                        if (res.status === 200) {
                            this.isIssuingSuccessLoading = false;
                            // this.$router.push({ name: 'ContactUsFinishPage' });
                            this.handleIssuingCheckDialogClose();
                            this.isIssuingSuccessDialogVisible = true;
                            this.$nextTick(() => {
                                this.name = undefined;
                                this.email = undefined;
                            });
                        } else {
                            // 실패 처리
                            alert(`오류 발생: ${res.data}. 다시 시도해 주세요.`);
                            this.isIssuingSuccessLoading = false;
                        }
                    }).catch((error) => {
                        this.isIssuingSuccessLoading = false;

                        if (error.response) {
                            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);

                            alert('오류 발생. 다시 시도해 주세요.');
                                    
                            // this.errMsg = error.response.request;
                            // this.activeModalErr = true;
                            // console.log('errMsg', this.errMsg);
                        }
                        else if (error.request) {
                            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                            // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
                            // Node.js의 http.ClientRequest 인스턴스입니다.
                            console.log(error.request);

                            alert('오류 발생. 다시 시도해 주세요.');

                            // this.errMsg = error.request;
                            // this.activeModalErr = true;
                        }
                        else {
                            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                            console.log('Error', error.message);

                            this.errMsg = error.message;
                            this.activeModalErr = true;
                        }
                        console.log(error.config);
                    }).finally(() => { 
                        console.log('end');
                        // this.$refs['btnIssuing'].disabled = false;
                    });

                // API 호출
                // const response = await this.issueBadge({
                //     lang: this.lang,
                //     name: this.name,
                //     email: this.email
                // });               

                // console.log('response.data.result', response.data);

                // if (response.data.result === 'Requested') { 
                //     this.handleIssuingCheckDialogClose();
                //     this.isIssuingSuccessDialogVisible = true;
                //     console.log('완료!');
                // }
            } catch (error) {
                this.handleError(error);
            } finally {
                console.log('Request completed');
                // 필요에 따라 버튼 비활성화 복원 등
                // this.$refs['btnInquery'].disabled = false;
            }
        },
        handleError(error) {
            // 에러 핸들링 함수
            if (error.response) {
                // 요청이 이루어졌으나 에러 응답을 받은 경우
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
            } else if (error.request) {
                // 요청이 이루어졌으나 응답이 없는 경우
                console.error('Error Request:', error.request);
                alert('서버와의 연결에 실패했습니다. 인터넷 연결을 확인해 주세요.');
            } else {
                // 요청 설정 중 오류가 발생한 경우
                console.error('Error Message:', error.message);
                alert('오류가 발생했습니다. 다시 시도해 주세요.');
            }
            console.error('Error Config:', error.config);
        },
        /* ============================================================
         * ANCHOR: 확인 버튼 클릭 (발행 완료)
         * ============================================================ */
        handleIssuingSuccessDialogClose() {
            this.isIssuingSuccessDialogVisible = false;
            this.resetForm();
        },
        resetForm() {
            this.name = '';
            this.email = '';
            this.$refs.form.resetValidation();
        },
    },
    // !SECTION: Methods
};
</script>
